import { createGlobalStyle } from 'styled-components';
import { useLayoutEffect, useRef, useState } from 'react';

import { DesignDensity, DesignTemplateName, DesignColorPalette, DesignFontFamily } from '../../../generated/types';
import { getColorPalette } from '../../../helper/colorPalette';
import { Colors } from '../../../styles/colors';

import {
  Container,
  InnerContainer,
  Page,
  PageBody,
  PageContainer,
  PageNavigator,
  PageNavigatorActive,
  PageSidebar,
  PagesList,
  SectionContainer,
  SectionSize,
} from './CoverLetterPreview.styles';
import { ErrorBoundary } from '../ErrorBoundary';
import { ChevronLeftIcon, ChevronRightIcon } from '../../atoms/Icons';
import { IconButton } from '../../atoms/Icons/Icon';
import { CoverLetterPreviewTaurus } from './CoverLetterPreviewTaurus';
import { CoverLetterPreviewCancer } from './CoverLetterPreviewCancer';
import { CoverLetterPreviewAries } from './CoverLetterPreviewAries';
import { CoverLetterPreviewVirgo } from './CoverLetterPreviewVirgo';
import { CoverLetterPreviewLibra } from './CoverLetterPreviewLibra';
import { CoverLetterPreviewScorpio } from './CoverLetterPreviewScorpio';

const PAGE_HEIGHT = 1030;

export const CoverLetterPreview = ({ state }: { state: any }) => {
  const [page, setPage] = useState<number>(0);
  const [maxPage, setMaxPage] = useState<number>(0);

  function handleNextPage() {
    setPage((prev) => Math.min(prev + 1, maxPage));
  }

  function handlePrevPage() {
    setPage((prev) => Math.max(prev - 1, 0));
  }

  if (!state) return null;

  const { design } = state;
  if (!design) return null;

  const {
    templateName = DesignTemplateName.Aries,
    colorPalette = DesignColorPalette.Default,
    density = DesignDensity.Normal,
    fontFamily = DesignFontFamily.Palatino,
  } = design;

  const {
    primaryColor,
    secondaryColor,
    contrastColor,
    backgroundColor,
    textColor,
    headerColor,
    sidebarBackgroundColor,
    sidebarHeaderColor,
    sidebarTextColor,
  } = getColorPalette(colorPalette);
  const { fontSize, lineHeight } = getTypographyOptions(density, fontFamily, templateName);

  const GlobalStyles = createGlobalStyle`
    body {
      margin: 0;
      overflow: hidden;
      box-sizing: border-box;
    }
    *, *::before, *::after {
      box-sizing: border-box;
    }
    html {
      --design-font-family: ${fontFamily};
      --design-font-size: ${fontSize};
      --design-line-height: ${lineHeight};
      --design-primary-color: ${primaryColor};
      --design-secondary-color: ${secondaryColor};
      --design-contrast-color: ${contrastColor};
      --design-background-color: ${backgroundColor};
      --design-header-color: ${headerColor};
      --design-text-color: ${textColor};
      --design-sidebar-background-color: ${sidebarBackgroundColor};
      --design-sidebar-text-color: ${sidebarHeaderColor};
      --design-sidebar-header-color: ${sidebarTextColor};
    }
  `;

  let headerNodes: React.ReactNode[] = [];
  let sidebarNodes: React.ReactNode[] = [];
  let mainNodes: React.ReactNode[] = [];
  switch (templateName) {
    case 'ARIES':
      headerNodes = CoverLetterPreviewAries(state);
      break;
    case 'TAURUS':
      headerNodes = CoverLetterPreviewTaurus(state);
      break;
    case 'CANCER':
      headerNodes = CoverLetterPreviewCancer(state).headerNodes;
      sidebarNodes = CoverLetterPreviewCancer(state).sidebarNodes;
      mainNodes = CoverLetterPreviewCancer(state).mainNodes;
      break;
    case 'VIRGO':
      headerNodes = CoverLetterPreviewVirgo(state);
      break;
    case 'LIBRA':
      headerNodes = CoverLetterPreviewLibra(state);
      break;
    case 'SCORPIO':
      headerNodes = CoverLetterPreviewScorpio(state);
      break;
    default:
      mainNodes = CoverLetterPreviewAries(state);
  }
  const key =
    templateName.toLowerCase() +
    '-' +
    colorPalette.toLowerCase() +
    '-' +
    density.toLowerCase() +
    '-' +
    fontFamily.toLowerCase();

  return (
    <Container>
      <GlobalStyles />
      {/* <pre>{JSON.stringify({ mainNodes }, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify({ page, state, maxPage }, null, 2)}</pre> */}
      <InnerContainer $page={page > maxPage ? maxPage : page}>
        <Pages
          headerElements={headerNodes}
          sidebarElements={sidebarNodes}
          mainElements={mainNodes}
          setMaxPage={setMaxPage}
          key={key}
        />
      </InnerContainer>
      {maxPage > 0 && (
        <PageNavigator>
          <IconButton onClick={handlePrevPage}>
            <ChevronLeftIcon size={2} color={page === 0 ? Colors.GreyDarkest : Colors.Primary} />
          </IconButton>
          <PageNavigatorActive>{page + 1}</PageNavigatorActive>/ {maxPage + 1}
          <IconButton onClick={handleNextPage}>
            <ChevronRightIcon size={2} color={page === maxPage ? Colors.GreyDarkest : Colors.Primary} />
          </IconButton>
        </PageNavigator>
      )}
    </Container>
  );
};

const Pages = ({ headerElements, sidebarElements, mainElements, setMaxPage }: any) => {
  const [elementHeights, setElementHeights] = useState<any>({});

  const updateElementHeight = (index: string, height: number) => {
    setElementHeights((prevHeights) => {
      return {
        ...prevHeights,
        [index]: height,
      };
    });
  };

  const pages: any = [[]];
  const pageHeight = PAGE_HEIGHT;
  let pageIndex = 0;
  let currentHeight = 0;

  try {
    headerElements?.forEach((element: any, elementIndex: number) => {
      const elementHeight = elementHeights[`header-${elementIndex}`];
      const displayName = element?.type?.displayName || '';
      if (displayName === 'PageBreak' || currentHeight + elementHeight > pageHeight) {
        pageIndex += 1;
        currentHeight = elementHeight;
        pages[pageIndex] = [];
        pages[pageIndex].push({
          element,
          elementIndex: `header-${elementIndex}`,
          type: 'header',
          elementHeight,
          currentHeight,
        });
      } else {
        currentHeight = currentHeight + elementHeight;
        pages[pageIndex].push({
          element,
          elementIndex: `header-${elementIndex}`,
          type: 'header',
          elementHeight,
          currentHeight,
        });
      }
    });

    const headerHeight = currentHeight;
    mainElements.forEach((element: any, elementIndex: number) => {
      const elementHeight = elementHeights[`main-${elementIndex}`];
      const displayName = element?.type?.displayName || '';
      if (displayName === 'PageBreak' || currentHeight + elementHeight > pageHeight) {
        pageIndex += 1;
        pages[pageIndex] = [];
        pages[pageIndex].push({
          element,
          elementIndex: `main-${elementIndex}`,
          type: 'main',
          elementHeight,
          currentHeight,
        });
        currentHeight = elementHeight;
      } else {
        currentHeight = currentHeight + elementHeight;
        pages[pageIndex].push({
          element,
          elementIndex: `main-${elementIndex}`,
          type: 'main',
          elementHeight,
          currentHeight,
        });
      }
    });
    let sidebarPageIndex = 0;
    currentHeight = headerHeight;
    sidebarElements?.forEach((element: any, elementIndex: number) => {
      const elementHeight = elementHeights[`sidebar-${elementIndex}`];
      if (currentHeight + elementHeight > pageHeight) {
        sidebarPageIndex += 1;
        if (!pages[sidebarPageIndex]) pages[sidebarPageIndex] = [];
        pages[sidebarPageIndex].push({
          element,
          elementIndex: `sidebar-${elementIndex}`,
          type: 'sidebar',
          elementHeight,
          currentHeight,
        });
        currentHeight = elementHeight;
      } else {
        currentHeight = currentHeight + elementHeight;
        pages[sidebarPageIndex].push({
          element,
          elementIndex: `sidebar-${elementIndex}`,
          type: 'sidebar',
          elementHeight,
          currentHeight,
        });
      }
    });
    setMaxPage(pageIndex);
  } catch (error) {
    console.error('error', error);
  }

  return (
    <PagesList>
      {pages.map((elements: any, pageIndex: number) => {
        return (
          <Page key={pageIndex}>
            {elements
              .filter((element: any) => element.type === 'header')
              .map(({ element, elementIndex, type }: any) => {
                return (
                  <Section
                    key={elementIndex}
                    updateElementHeight={(height: number) => updateElementHeight(elementIndex, height)}
                  >
                    {element}
                  </Section>
                );
              })}
            <PageContainer>
              <PageSidebar>
                {elements
                  .filter((element: any) => element.type === 'sidebar')
                  .map(({ element, elementIndex, type }: any) => {
                    return (
                      <Section
                        key={elementIndex}
                        updateElementHeight={(height: number) => updateElementHeight(elementIndex, height)}
                      >
                        {element}
                      </Section>
                    );
                  })}
              </PageSidebar>
              <PageBody>
                {elements
                  .filter((element: any) => element.type === 'main')
                  .map(({ element, elementIndex, type }: any) => {
                    return (
                      <Section
                        key={elementIndex}
                        updateElementHeight={(height: number) => updateElementHeight(elementIndex, height)}
                      >
                        {element}
                      </Section>
                    );
                  })}
              </PageBody>
            </PageContainer>
          </Page>
        );
      })}
    </PagesList>
  );
};

const Section = ({ children, updateElementHeight }: any) => {
  const elementRef = useRef(null);
  // const [height, setHeight] = useState<number>(0);

  useLayoutEffect(() => {
    if (elementRef.current) {
      const height = elementRef.current.clientHeight || 100;
      // setHeight(height);
      updateElementHeight(height);
    }
  }, [children]);

  return (
    <SectionContainer ref={elementRef}>
      {/* <SectionSize>{height}</SectionSize> */}
      <ErrorBoundary message="ResumePreview/Page/Section">{children}</ErrorBoundary>
    </SectionContainer>
  );
};

function getTypographyOptions(
  density: DesignDensity,
  fontFamily: DesignFontFamily,
  templateName: DesignTemplateName = DesignTemplateName.Aries
): { fontSize: string; lineHeight: string } {
  let fontSize = '13pt';
  let lineHeight = '12pt';
  let scale = 1;
  switch (fontFamily) {
    case DesignFontFamily.Palatino:
      scale = 0.95; // OK
      break;
    case DesignFontFamily.Helvetica:
      scale = 0.945;
      break;
    case DesignFontFamily.Courier:
      scale = 0.9;
      break;
  }

  if (templateName === DesignTemplateName.Taurus) {
    switch (fontFamily) {
      case DesignFontFamily.Palatino:
        scale = 1.015; // OK
        break;
      case DesignFontFamily.Helvetica:
        scale = 1;
        break;
      case DesignFontFamily.Courier:
        scale = 0.85;
        break;
    }
  }

  switch (density) {
    case DesignDensity.Dense:
      fontSize = `${10 * scale}pt`;
      lineHeight = '10pt';
      break;
    case DesignDensity.Normal:
      fontSize = `${12 * scale}pt`;
      lineHeight = fontFamily === DesignFontFamily.Courier ? '12pt' : '14pt';
      break;
    case DesignDensity.Loose:
      fontSize = `${14 * scale}pt`;
      lineHeight = '14pt';
      break;
  }

  return { fontSize, lineHeight };
}
