import styled from 'styled-components';

import { Colors } from '../../../styles/colors';

export const Container = styled.div`
  background-color: var(--design-background-color);
  color: var(--design-text-color);
  font-family: var(--design-font-family);
  font-size: var(--design-font-size);
  position: relative;
  overflow: hidden;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 29.7cm;
`;

export const InnerContainer = styled.div<{ $page: number }>`
  position: relative;
  top: ${({ $page }) => $page * -1155}px; // 1122 + 32
`;

export const PagesList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  background-color: ${Colors.GreyLightest};
`;

export const Page = styled.div`
  width: 21cm;
  height: 29.7cm;
  padding: 17.5mm 15mm 17.5mm 15mm;
  min-width: 0;
  background-color: var(--design-background-color);
  display: flex;
  flex-direction: column;
`;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const PageSidebar = styled.div`
  width: 30%;
`;

export const PageBody = styled.div`
  width: 65%;
`;

export const SectionContainer = styled.div`
  position: relative;
`;

export const PageNavigator = styled.div`
  position: absolute;
  bottom: 32px;
  right: 16px;
  width: fit-content;
  background-color: ${Colors.GreyDarkest};
  border-radius: 12px;
  font-size: 32px;
  padding: 16px 16px;
  color: ${Colors.GreyLight};

  button {
    padding: 0;
    background-color: transparent;
    border: none;
    font-size: 24px;
  }

  svg {
    cursor: pointer;
  }

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

export const PageNavigatorActive = styled.span`
  color: white;
`;

export const CoverLetterPreviewBlock = styled.div``;

export const CoverLetterPreviewSmall = styled.div`
  display: flex;
  font-size: 0.9em;
  font-weight: normal;
`;

export const CoverLetterPreviewNormal = styled.div`
  display: flex;
  font-size: 1em;
  font-weight: normal;
`;

export const CoverLetterPreviewlarge = styled.div`
  display: flex;
  font-size: 1.1em;
  line-height: 1.1em;
  font-weight: normal;
`;

export const CoverLetterPreviewLarge = styled.div`
  display: flex;
  font-size: 1.2em;
  line-height: 1.2em;
  font-weight: normal;
`;

export const CoverLetterPreviewLARGE = styled.div`
  display: flex;
  font-size: 1.4em;
  line-height: 1.4em;
  font-weight: normal;
`;

export const CoverLetterPreviewhuge = styled.div`
  display: flex;
  font-size: 1.7em;
  line-height: 1.7em;
  font-weight: normal;
`;

export const CoverLetterPreviewHuge = styled.div`
  display: flex;
  font-size: 2em;
  line-height: 1em;
  font-weight: normal;
`;

export const CoverLetterPreviewHUGE = styled.div`
  display: flex;
  font-size: 2.5em;
  line-height: 2.5em;
  font-weight: normal;
`;

export const CoverLetterPreviewCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
`;

export const CoverLetterPreviewLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 auto;
`;

export const CoverLetterPreviewRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 0 auto;
`;

export const CoverLetterBigSkip = styled.div`
  height: 2em;
`;

// This is for debugging
export const SectionSize = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${Colors.Black};
  color: ${Colors.White};
  font-size: 24px;
`;
