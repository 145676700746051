import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone, faLocationDot } from '@fortawesome/free-solid-svg-icons';

import { Spacer } from '../../atoms/Spacer/Spacer';
import { CoverLetterPreviewSmall } from './CoverLetterPreview.styles';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

export const CoverLetterPreviewScorpioBasic = ({ name, jobTitle }: { name?: string; jobTitle?: string }) => {
  return (
    <div
      style={{
        marginBottom: '2em',
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'flex-end',
      }}
    >
      {name ? (
        <div
          style={{
            fontSize: '46px',
            color: 'var(--design-primary-color)',
          }}
        >
          {name}
        </div>
      ) : null}
      {jobTitle ? (
        <div
          style={{
            fontSize: '24px',
            color: 'var(--design-secondary-color)',
          }}
        >
          {jobTitle}
        </div>
      ) : null}
    </div>
  );
};

export const CoverLetterPreviewScorpioContact = ({
  email,
  phone,
  fullAddress,
  linkedin,
}: CoverLetterPreviewScorpioContactProps) => {
  const contacts = [];
  if (email) contacts.push(email);
  if (phone) contacts.push(phone);
  if (fullAddress) contacts.push(fullAddress);

  return (
    <div
      style={{
        marginBottom: '2em',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {email && (
        <>
          <CoverLetterPreviewScorpioInline
            style={{
              fontSize: '1rem',
            }}
          >
            <CoverLetterPreviewScorpioIcon icon={faEnvelope} />
            <b>{email}</b>
          </CoverLetterPreviewScorpioInline>
          <Spacer y={4} />
        </>
      )}
      {phone && (
        <>
          <CoverLetterPreviewScorpioInline
            style={{
              fontSize: '1rem',
            }}
          >
            <CoverLetterPreviewScorpioIcon icon={faPhone} />
            <b>{phone}</b>
          </CoverLetterPreviewScorpioInline>
          <Spacer y={4} />
        </>
      )}
      {fullAddress && (
        <>
          <CoverLetterPreviewScorpioInline>
            <CoverLetterPreviewScorpioIcon icon={faLocationDot} />
            <CoverLetterPreviewSmall>{fullAddress}</CoverLetterPreviewSmall>
          </CoverLetterPreviewScorpioInline>
          <Spacer y={4} />
        </>
      )}
      {linkedin ? (
        <>
          <CoverLetterPreviewScorpioInline>
            <CoverLetterPreviewScorpioIcon icon={faLinkedin} />
            <CoverLetterPreviewSmall>
              <span
                style={{
                  color: 'var(--design-primary-color)',
                }}
              >
                /in/{linkedin}
              </span>
            </CoverLetterPreviewSmall>
          </CoverLetterPreviewScorpioInline>
          <Spacer y={4} />
        </>
      ) : null}
    </div>
  );
};

type CoverLetterPreviewScorpioContactProps = {
  email?: string;
  phone?: string;
  fullAddress?: string;
  linkedin?: string;
};

export const CoverLetterPreviewScorpioSummary = ({
  children,
  paragraphTextAlignment,
  hasParagraphIndendation,
}: {
  children?: React.ReactNode;
  paragraphTextAlignment: 'justify' | 'right' | 'left';
  hasParagraphIndendation: boolean;
}) => {
  if (!children) return null;
  return (
    <CoverLetterPreviewScorpioSummaryContent
      $paragraphTextAlignment={paragraphTextAlignment}
      $hasParagraphIndendation={hasParagraphIndendation}
      dangerouslySetInnerHTML={{ __html: children }}
    ></CoverLetterPreviewScorpioSummaryContent>
  );
};

export const CoverLetterPreviewScorpioSummaryContent = styled.div<{
  $hasParagraphIndendation: boolean;
  $paragraphTextAlignment: 'right' | 'left' | 'justify';
}>`
  width: 100%;
  margin: 0 auto;
  p {
    text-align: justify;
    line-height: 12pt;
  }
  ${(props) => {
    if (props.$paragraphTextAlignment === 'right') {
      return `
        p {
          text-align: right;
        }
      `;
    }
    if (props.$paragraphTextAlignment === 'left') {
      return `
        p {
          text-align: left;
        }
      `;
    }
    if (props.$paragraphTextAlignment === 'justify' && props.$hasParagraphIndendation) {
      return `
        p {
          text-indent: 24pt;
        }
      `;
    }
  }}
`;

/* Custom styles */
const CoverLetterPreviewScorpioIcon = styled(FontAwesomeIcon)`
  color: var(--design-primary-color);
  width: 0.9em;
`;

export const CoverLetterPreviewScorpioSpan = styled.span``;

export const CoverLetterPreviewScorpioInline = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 1em;
`;

export const CoverLetterPreviewScorpioSection = styled.div`
  border-bottom: 1px solid black;
  text-transform: capitalize;
  margin-top: 8pt;
`;

export const CoverLetterPreviewScorpioBothSide = styled.div`
  display: flex;
  justify-content: space-between;
`;
