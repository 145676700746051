import { Spacer } from '../../atoms/Spacer/Spacer';
import { serializeSlateNodeToSection } from '../SlateEditor/helper';
import { CoverLetterBigSkip } from './CoverLetterPreview.styles';
import { State } from './CoverLetterPreview.types';
import {
  CoverLetterPreviewAriesBasic,
  CoverLetterPreviewAriesColoredBlock,
  CoverLetterPreviewAriesContact,
  CoverLetterPreviewAriesSummary,
} from './CoverLetterPreviewAries.styles';

export const CoverLetterPreviewAries = (state: State) => {
  try {
    const { coverLetter = {}, design = {} } = state;
    const { hasParagraphIndendation = false, paragraphTextAlignment = 'justify' } = design;
    const { basics = {}, contacts = {}, content = '' } = coverLetter;
    const formattedContent = content ? serializeSlateNodeToSection(content) : '';
    const { name, jobTitle } = basics;
    const { email = '', phone = '', linkedin = '', country = '', city = '' } = contacts;
    const fullAddress = city && country ? `${city}, ${country}` : city ? city : country;

    const nodes = [
      <CoverLetterPreviewAriesBasic jobTitle={jobTitle} name={name} />,
      <CoverLetterPreviewAriesContact email={email} phone={phone} fullAddress={fullAddress} linkedin={linkedin} />,
      <CoverLetterPreviewAriesColoredBlock />,
      <CoverLetterBigSkip />,
      <CoverLetterBigSkip />,
      ...formattedContent.map((content: string) => {
        return (
          <CoverLetterPreviewAriesSummary
            hasParagraphIndendation={hasParagraphIndendation}
            paragraphTextAlignment={paragraphTextAlignment}
          >
            {content}
          </CoverLetterPreviewAriesSummary>
        );
      }),
    ];

    return nodes;
  } catch (error) {
    console.error(error);
    return [];
  }
};
