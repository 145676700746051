import styled from 'styled-components';

import { Spacer } from '../../atoms/Spacer/Spacer';
import { CoverLetterPreviewCenter, CoverLetterPreviewHuge } from './CoverLetterPreview.styles';

export const CoverLetterPreviewAriesBasic = ({ name, jobTitle }: { name?: string; jobTitle?: string }) => {
  return (
    <CoverLetterPreviewCenter
      style={{
        marginBottom: '2em',
      }}
    >
      {name ? <CoverLetterPreviewHuge>{name}</CoverLetterPreviewHuge> : null}
      {jobTitle ? <CoverLetterPreviewHuge>{jobTitle}</CoverLetterPreviewHuge> : null}
    </CoverLetterPreviewCenter>
  );
};

export const CoverLetterPreviewAriesContact = ({
  email,
  phone,
  fullAddress,
  linkedin,
}: CoverLetterPreviewAriesContactProps) => {
  const contacts = [];
  if (email) contacts.push(email);
  if (phone) contacts.push(phone);
  if (fullAddress) contacts.push(fullAddress);

  return (
    <CoverLetterPreviewCenter
      style={{
        marginBottom: '2em',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
        {contacts.map((contact, index) => {
          const isLast = index === contacts.length - 1;
          return (
            <div key={index}>
              <span>{contact}</span>
              {!isLast && <CoverLetterPreviewAriesDot />}
            </div>
          );
        })}
      </div>
      <div>{linkedin ? <CoverLetterPreviewAriesSpan>{linkedin}</CoverLetterPreviewAriesSpan> : null}</div>
      <Spacer y={8} />
    </CoverLetterPreviewCenter>
  );
};

type CoverLetterPreviewAriesContactProps = {
  email?: string;
  phone?: string;
  fullAddress?: string;
  linkedin?: string;
};

export const CoverLetterPreviewAriesSummary = ({
  children,
  paragraphTextAlignment,
  hasParagraphIndendation,
}: {
  children?: React.ReactNode;
  paragraphTextAlignment: 'justify' | 'right' | 'left';
  hasParagraphIndendation: boolean;
}) => {
  if (!children) return null;
  return (
    <CoverLetterPreviewAriesSummaryContent
      $paragraphTextAlignment={paragraphTextAlignment}
      $hasParagraphIndendation={hasParagraphIndendation}
      dangerouslySetInnerHTML={{ __html: children }}
    ></CoverLetterPreviewAriesSummaryContent>
  );
};

export const CoverLetterPreviewAriesSummaryContent = styled.div<{
  $hasParagraphIndendation: boolean;
  $paragraphTextAlignment: 'right' | 'left' | 'justify';
}>`
  width: 100%;
  margin: 0 auto;
  p {
    text-align: justify;
    line-height: 12pt;
  }
  ${(props) => {
    if (props.$paragraphTextAlignment === 'right') {
      return `
        p {
          text-align: right;
        }
      `;
    }
    if (props.$paragraphTextAlignment === 'left') {
      return `
        p {
          text-align: left;
        }
      `;
    }
    if (props.$paragraphTextAlignment === 'justify' && props.$hasParagraphIndendation) {
      return `
        p {
          text-indent: 24pt;
        }
      `;
    }
  }}
`;

export const CoverLetterPreviewAriesColoredBlock = styled.div`
  margin: 0 auto;
  width: 80%;
  background-color: var(--design-primary-color);
  height: 8px;
  margin-bottom: 1rem;
`;

/* Custom styles */
export const CoverLetterPreviewAriesWithDots = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1em;
  justify-content: center;
  flex-wrap: wrap;

  span {
    &::before {
      content: '';
      margin: 0.5rem;
      position: absolute;
      height: 5px;
      width: 5px;
      background-color: var(--design-text-color);
      border-radius: 50%;
      transform: translateX(-20px);
    }
  }
`;

export const CoverLetterPreviewAriesDot = styled.span`
  display: inline-block;
  align-items: center;
  justify-content: center;
  margin: 0 0.5rem;
  height: 5px;

  &:after {
    content: '';
    position: absolute;
    font-size: 1.6rem;
    height: 2px;
    width: 2px;
    background-color: var(--design-text-color);
    border-radius: 50%;
  }
`;

export const CoverLetterPreviewAriesSpan = styled.span``;

export const CoverLetterPreviewAriesInline = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 1em;
`;

export const CoverLetterPreviewAriesSection = styled.div`
  border-bottom: 1px solid black;
  text-transform: capitalize;
  margin-top: 8pt;
`;

export const CoverLetterPreviewAriesBothSide = styled.div`
  display: flex;
  justify-content: space-between;
`;
