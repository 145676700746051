import { serializeSlateNodeToSection } from '../SlateEditor/helper';
import { CoverLetterBigSkip } from './CoverLetterPreview.styles';
import { State } from './CoverLetterPreview.types';
import {
  CoverLetterPreviewScorpioBasic,
  CoverLetterPreviewScorpioContact,
  CoverLetterPreviewScorpioSummary,
} from './CoverLetterPreviewScorpio.styles';

export const CoverLetterPreviewScorpio = (state: State) => {
  try {
    const { coverLetter = {}, design = {} } = state;
    const { hasParagraphIndendation = false, paragraphTextAlignment = 'justify' } = design;
    const { basics = {}, contacts = {}, content = '' } = coverLetter;
    const formattedContent = content ? serializeSlateNodeToSection(content) : '';
    const { name, jobTitle } = basics;
    const { email = '', phone = '', linkedin = '', country = '', city = '' } = contacts;
    const fullAddress = city && country ? `${city}, ${country}` : city ? city : country;
    const linkedinPath = linkedin.split('linkedin.com/in/')[1];

    const nodes = [
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '1rem',
          width: '100%',
        }}
      >
        <div
          style={{
            flex: 1,
          }}
        >
          <CoverLetterPreviewScorpioContact
            fullAddress={fullAddress}
            email={email}
            phone={phone}
            linkedin={linkedinPath}
          />
        </div>
        <div
          style={{
            flex: 1,
            textAlign: 'right',
          }}
        >
          <CoverLetterPreviewScorpioBasic jobTitle={jobTitle} name={name} />
        </div>
      </div>,
      <CoverLetterBigSkip />,
      <CoverLetterBigSkip />,
      ...formattedContent.map((content: string) => {
        return (
          <CoverLetterPreviewScorpioSummary
            hasParagraphIndendation={hasParagraphIndendation}
            paragraphTextAlignment={paragraphTextAlignment}
          >
            {content}
          </CoverLetterPreviewScorpioSummary>
        );
      }),
    ];

    return nodes;
  } catch (error) {
    console.error(error);
    return [];
  }
};
