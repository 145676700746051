import { serializeSlateNodeToSection } from '../SlateEditor/helper';
import {
  CoverLetterPreviewCancerBasic,
  CoverLetterPreviewCancerContact,
  CoverLetterPreviewCancerSection,
  CoverLetterPreviewCancerSummary,
} from './CoverLetterPreviewCancer.styles';
import { State } from './CoverLetterPreview.types';
import { CoverLetterBigSkip } from './CoverLetterPreview.styles';

export const CoverLetterPreviewCancer = (state: State) => {
  try {
    const { coverLetter = {}, design = {} } = state;
    const { hasParagraphIndendation = false, paragraphTextAlignment = 'justify' } = design;
    const { basics = {}, contacts = {}, content = '' } = coverLetter;
    const formattedContent = content ? serializeSlateNodeToSection(content) : '';
    const { name, jobTitle } = basics;
    const { email = '', phone = '', linkedin = '', country = '', city = '' } = contacts;
    const fullAddress = city && country ? `${city}, ${country}` : city ? city : country;
    const linkedinPath = linkedin.split('linkedin.com/in/')[1];

    const headerNodes = [<CoverLetterPreviewCancerBasic jobTitle={jobTitle} name={name} />];
    const sidebarNodes = [
      <CoverLetterBigSkip />,
      <CoverLetterBigSkip />,
      <CoverLetterPreviewCancerSection>Contact</CoverLetterPreviewCancerSection>,
      <CoverLetterPreviewCancerContact fullAddress={fullAddress} email={email} phone={phone} linkedin={linkedinPath} />,
    ];
    const mainNodes = [
      <CoverLetterBigSkip />,
      <CoverLetterBigSkip />,
      ...formattedContent.map((content: string) => {
        return (
          <CoverLetterPreviewCancerSummary
            hasParagraphIndendation={hasParagraphIndendation}
            paragraphTextAlignment={paragraphTextAlignment}
          >
            {content}
          </CoverLetterPreviewCancerSummary>
        );
      }),
    ];

    return { headerNodes, sidebarNodes, mainNodes };
  } catch (error) {
    console.error(error);
    return { headerNodes: [], sidebarNodes: [], mainNodes: [] };
  }
};
