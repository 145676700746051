import styled from 'styled-components';

import { CoverLetterPreviewHuge, CoverLetterPreviewRight } from './CoverLetterPreview.styles';

export const CoverLetterPreviewTaurusBasic = ({ name, jobTitle }: { name?: string; jobTitle?: string }) => {
  return (
    <CoverLetterPreviewRight
      style={{
        marginBottom: '2em',
      }}
    >
      {name ? (
        <CoverLetterPreviewHuge
          style={{
            color: 'var(--design-primary-color)',
          }}
        >
          {name}
        </CoverLetterPreviewHuge>
      ) : null}
      {jobTitle ? (
        <CoverLetterPreviewHuge
          style={{
            color: 'var(--design-secondary-color)',
          }}
        >
          {jobTitle}
        </CoverLetterPreviewHuge>
      ) : null}
    </CoverLetterPreviewRight>
  );
};

export const CoverLetterPreviewTaurusContact = ({
  email,
  phone,
  fullAddress,
  linkedin,
}: CoverLetterPreviewTaurusContactProps) => {
  const contacts = [];
  if (email) contacts.push(email);
  if (phone) contacts.push(phone);
  if (fullAddress) contacts.push(fullAddress);
  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
        {contacts.map((contact, index) => {
          const isLast = index === contacts.length - 1;
          return (
            <div key={index}>
              <b>{contact}</b>
              {!isLast && <CoverLetterPreviewTaurusDot />}
            </div>
          );
        })}
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
        {linkedin ? <span>{linkedin}</span> : null}
      </div>
    </>
  );
};

type CoverLetterPreviewTaurusContactProps = {
  email?: string;
  phone?: string;
  fullAddress?: string;
  linkedin?: string;
};

export const CoverLetterPreviewTaurusSummary = ({
  children,
  paragraphTextAlignment,
  hasParagraphIndendation,
}: {
  children?: React.ReactNode;
  paragraphTextAlignment: 'justify' | 'right' | 'left';
  hasParagraphIndendation: boolean;
}) => {
  if (!children) return null;
  return (
    <CoverLetterPreviewTaurusSummaryContent
      $paragraphTextAlignment={paragraphTextAlignment}
      $hasParagraphIndendation={hasParagraphIndendation}
      dangerouslySetInnerHTML={{ __html: children }}
    ></CoverLetterPreviewTaurusSummaryContent>
  );
};

const CoverLetterPreviewTaurusSummaryContent = styled.div<{
  $hasParagraphIndendation: boolean;
  $paragraphTextAlignment: 'right' | 'left' | 'justify';
}>`
  width: 100%;
  margin: 0 auto;
  p {
    text-align: justify;
    line-height: 12pt;
  }
  ${(props) => {
    if (props.$paragraphTextAlignment === 'right') {
      return `
        p {
          text-align: right;
        }
      `;
    }
    if (props.$paragraphTextAlignment === 'left') {
      return `
        p {
          text-align: left;
        }
      `;
    }
    if (props.$paragraphTextAlignment === 'justify' && props.$hasParagraphIndendation) {
      return `
        p {
          text-indent: 24pt;
        }
      `;
    }
  }}
`;

const CoverLetterPreviewTaurusDot = styled.span`
  border-left: 1px solid black;
  display: inline-block;
  align-items: center;
  justify-content: center;
  height: 8px;
  width: 8px;
  margin: 0 0.25em 0 0.5em;
`;
