import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

import { Spacer } from '../../atoms/Spacer/Spacer';
import {
  CoverLetterPreviewBlock,
  CoverLetterPreviewCenter,
  CoverLetterPreviewHuge,
  CoverLetterPreviewLARGE,
} from './CoverLetterPreview.styles';

export const CoverLetterPreviewCancerBasic = ({ name, jobTitle }: { name?: string; jobTitle?: string }) => {
  return (
    <CoverLetterPreviewCenter
      style={{ padding: '16pt', borderRadius: '4pt', background: 'var(--design-sidebar-background-color)' }}
    >
      {name ? (
        <CoverLetterPreviewHuge
          style={{ fontSize: '46pt', textTransform: 'uppercase', color: 'var(--design-primary-color)' }}
        >
          {name}
        </CoverLetterPreviewHuge>
      ) : null}
      <Spacer y={12} />
      {jobTitle ? (
        <CoverLetterPreviewLARGE
          style={{ fontSize: '20pt', textTransform: 'uppercase', color: 'var(--design-secondary-color)' }}
        >
          {jobTitle}
        </CoverLetterPreviewLARGE>
      ) : null}
    </CoverLetterPreviewCenter>
  );
};

export const CoverLetterPreviewCancerContact = ({
  email,
  phone,
  fullAddress,
  linkedin,
}: CoverLetterPreviewCancerContactProps) => {
  return (
    <CoverLetterPreviewBlock>
      {email && (
        <>
          <CoverLetterPreviewCancerInline>
            <CoverLetterPreviewCancerIcon icon={faEnvelope} />
            {email}
          </CoverLetterPreviewCancerInline>
          <Spacer y={4} />
        </>
      )}
      {phone && (
        <>
          <CoverLetterPreviewCancerInline>
            <CoverLetterPreviewCancerIcon icon={faPhone} />
            {phone}
          </CoverLetterPreviewCancerInline>
          <Spacer y={4} />
        </>
      )}
      {fullAddress && (
        <>
          <CoverLetterPreviewCancerInline>
            <CoverLetterPreviewCancerIcon icon={faEnvelope} />
            {fullAddress}
          </CoverLetterPreviewCancerInline>
          <Spacer y={4} />
        </>
      )}
      {linkedin ? (
        <>
          <CoverLetterPreviewCancerInline>
            <CoverLetterPreviewCancerIcon icon={faLinkedin} /> {linkedin}
          </CoverLetterPreviewCancerInline>
          <Spacer y={4} />
        </>
      ) : null}
    </CoverLetterPreviewBlock>
  );
};

type CoverLetterPreviewCancerContactProps = {
  email?: string;
  phone?: string;
  fullAddress?: string;
  linkedin?: string;
};

export const CoverLetterPreviewCancerSummary = ({
  children,
  paragraphTextAlignment,
  hasParagraphIndendation,
}: {
  children?: React.ReactNode;
  paragraphTextAlignment: 'justify' | 'right' | 'left';
  hasParagraphIndendation: boolean;
}) => {
  if (!children) return null;
  return (
    <>
      <CoverLetterPreviewCancerSummaryContent
        $paragraphTextAlignment={paragraphTextAlignment}
        $hasParagraphIndendation={hasParagraphIndendation}
        dangerouslySetInnerHTML={{ __html: children }}
      ></CoverLetterPreviewCancerSummaryContent>
    </>
  );
};

export const CoverLetterPreviewCancerSummaryContent = styled.div<{
  $hasParagraphIndendation: boolean;
  $paragraphTextAlignment: 'right' | 'left' | 'justify';
}>`
  width: 100%;
  margin: 0 auto;
  p {
    text-align: justify;
    line-height: 12pt;
  }
  ${(props) => {
    if (props.$paragraphTextAlignment === 'right') {
      return `
        p {
          text-align: right;
        }
      `;
    }
    if (props.$paragraphTextAlignment === 'left') {
      return `
        p {
          text-align: left;
        }
      `;
    }
    if (props.$paragraphTextAlignment === 'justify' && props.$hasParagraphIndendation) {
      return `
        p {
          text-indent: 24pt;
        }
      `;
    }
  }}
`;

export const CoverLetterPreviewCancerSection = styled.div`
  border-bottom: 1pt solid var(--design-primary-color);
  font-size: 1.2em;
  line-height: 1.2em;
  margin-bottom: 0.5em;
  color: var(--design-header-color);
  margin-top: 1em;
`;

const CoverLetterPreviewCancerInline = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 0.4em;
`;

const CoverLetterPreviewCancerIcon = styled(FontAwesomeIcon)`
  color: var(--design-primary-color);
  width: 1em;
`;
