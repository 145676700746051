import { serializeSlateNodeToSection } from '../SlateEditor/helper';
import { CoverLetterBigSkip } from './CoverLetterPreview.styles';
import { State } from './CoverLetterPreview.types';
import {
  CoverLetterPreviewVirgoBasic,
  CoverLetterPreviewVirgoColoredBlock,
  CoverLetterPreviewVirgoContact,
  CoverLetterPreviewVirgoSummary,
} from './CoverLetterPreviewVirgo.styles';

export const CoverLetterPreviewVirgo = (state: State) => {
  try {
    const { coverLetter = {}, design = {} } = state;
    const { hasParagraphIndendation = false, paragraphTextAlignment = 'justify' } = design;
    const { basics = {}, contacts = {}, content = '' } = coverLetter;
    const formattedContent = content ? serializeSlateNodeToSection(content) : '';
    const { name, jobTitle } = basics;
    const { email = '', phone = '', linkedin = '', country = '', city = '' } = contacts;
    const fullAddress = city && country ? `${city}, ${country}` : city ? city : country;

    const nodes = [
      <CoverLetterPreviewVirgoBasic jobTitle={jobTitle} name={name} />,
      <CoverLetterPreviewVirgoContact email={email} phone={phone} fullAddress={fullAddress} linkedin={linkedin} />,
      <CoverLetterPreviewVirgoColoredBlock />,
      <CoverLetterBigSkip />,
      <CoverLetterBigSkip />,
      ...formattedContent.map((content: string) => {
        return (
          <CoverLetterPreviewVirgoSummary
            hasParagraphIndendation={hasParagraphIndendation}
            paragraphTextAlignment={paragraphTextAlignment}
          >
            {content}
          </CoverLetterPreviewVirgoSummary>
        );
      }),
    ];

    return nodes;
  } catch (error) {
    console.error(error);
    return [];
  }
};
